import React from "react";

import "./Mainbaner.css";
function Mainbaner() {
  const BannerButton = {
    marginTop: "2em",
    padding: "13px 30px",
  };
  return (
    <>
      {/* <div className="mt-5 pt-5">
        <div className="mainbanner">
          <section>
            <section>
              <video className="" src={SlideOneImage} loop autoPlay muted />
              <div className="callout">
                <h1>Hi, I'm Bosco</h1>
                <div className="desc">
                  They say every wrinkle is worth a thousand cuddles. I just
                  need a home and loving family to prove it.
                </div>
                <div className="button">
                  <div className="inner">
                    Take Me Home!<div></div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </div> */}
      {/* <section className="outter option1">
        <section className="video-container">
          <video
            className="mainvideo"
            src={SlideOneImage}
            loop
            autoPlay
            muted
          />
          <div className="callout">
            <SectionView>
              <div>
                <p className="homepage-banner-top-text homepage-banner-top-text-margin">
                  India’s largest commercial real estate advisory
                </p>
                <h1 className="homepage-banner-heading">
                  Let’s find you a <br />
                  new workspace
                </h1>
                <p className="homepage-banner-top-text">
                  We are Realistic Realtors, India’s largest commercial <br />
                  real estate service & advisory.
                </p>
                <a href="/mandated-properties">
                  <Button
                    style={BannerButton}
                    className={"white-button"}
                    title={"Find Properties"}
                  />
                </a>
              </div>
            </SectionView>
          </div>
        </section>
      </section> */}

      <div className="container-fluid homepage-bgimage"></div>
      <p>
        This example creates a full page background image. Try to resize the
        browser window to see how it always will cover the full screen (when
        scrolled to top), and that it scales nicely on all screen sizes.
      </p>
    </>
  );
}

export default Mainbaner;
