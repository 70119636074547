import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Home from "./Pages/Home/Home";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";

import ScreenRouter from "./Router/ScreenRouter";
import "react-toastify/dist/ReactToastify.css";
import Megaheader from "./Components/Header/Megaheader";
import "./App.css";
import { useEffect } from "react";
import { getUserFromToken } from "./service/auth/Authservice";
import { useDispatch } from "react-redux";
import { setUser } from "./redux/user";
const App = () => {
 
  return (
    <>
        {/* <Header /> */}
      <Megaheader />
      <div className="">
        <ScreenRouter />
      </div>
      <Footer />
    </>
  );
};

export default App;
