import React from "react";
import "./ArticleDetails.css";


import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import ApiService from "../../service/ApiService";
import { useNavigate } from "react-router-dom";

const ArticleDetails = () => {
  const [loadstate, setloadstate] = useState(false);
  const [articals, setArticals] = useState([]);

  const [data, setData] = useState();
  const [title, setTitle] = useState();
  const [imgBlog, setImgBlog] = useState();
  const [description, setDescription] = useState();

  const { state } = useLocation();
  const { stateCase } = useLocation();
  console.log(state, "state ==>");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);
  useEffect(() => {
    setData(state);
    setTitle(state?.ele?.title);
    setImgBlog(state?.ele?.image);
    setDescription(state?.ele?.description);
    RewardsList();
  });
  const RewardsList = async () => {
    const data = await ApiService.getData("/category/articles");
    console.log(data, "data ==>");

    setArticals(data);
  };
  const navigate = useNavigate();

  const handelOnclickArtical = (ele) => {
    navigate("/article-details", {
      state: {
        ele,
      },
    });
  };
  return (
    <div className="page-noice-bg a-d-p-container">
      <div className="a-d-p-banner-contianer">
        <div className="a-d-p-banner-left a-d-desktop">
          <h1 className="medium-heading a-d-p-heading">{title}</h1>
          {/* <Button className="a-d-p-button " title={"MARKET NEWS"} /> */}
          {/* <div className="social-icons-contianer">
            <a
              href="https://www.facebook.com/realisticrealtors"
              target="_blank"
            >
              <img
                src={facebook}
                className="mx-1 img-fluid h-100"
                alt="social-icon"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/realistic-realtors/"
              target="_blank"
            >
              <img src={linkedin} className="mx-1 h-100" alt="social-icon" />
            </a>
            <a
              href="https://www.instagram.com/realtorsrealistic/"
              target="_blank"
            >
              <img src={insta} className="mx-1 h-100" alt="social-icon" />
            </a>
            <a
              href="https://www.youtube.com/user/RealisticRealtors"
              target="_blank"
            >
              <img src={youtube} className="mx-1 h-100" alt="social-icon" />
            </a>
          </div> */}
        </div>
        <div className="a-d-p-banner-left a-d-mobile">
          {/* <Button className="a-d-p-button " title={"MARKET NEWS"} /> */}
          <h1 className="medium-heading a-d-p-heading a-d-head">
            Life sciences in Asia
            <br /> Pacific: A big booster for
            <br /> growth
          </h1>
          <p className="small-text a-d-desc">
            8 min read | 28/06/2022
            <br /> Written By
            <br /> Realistic Realtors
          </p>
          {/* <div className="a-d-p-social-container">
            <img className="a-d-p-social-icon" src={fbDark} alt="dabook icon" />
            <img className="a-d-p-social-icon" src={tDark} alt="dabook icon" />
            <img className="a-d-p-social-icon" src={inDark} alt="dabook icon" />
            <img
              className="a-d-p-social-icon"
              src={mailDark}
              alt="dabook icon"
            />
          </div> */}
        </div>
        <div className="a-d-p-banner-right">
          <img src={imgBlog} className="a-d-p-image" />
        </div>
      </div>

      <div className="a-d-p-details-container small-text">
        <p
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        ></p>
        {/* <p>
          The evolving real estate sector of India is re-establishing its
          foundation in the new normal of today. Supporting and nurturing this
          road to recovery are the various government initiatives that are
          predicted to provide the much needed impetus.
        </p> */}
      </div>

      {/* <div className="ap-ar-cards-container">
        <div className="article-cards-grid-cotnainer-parent">
          <div className="article-cards-grid-cotnainer">
            {loadstate ? (
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="">
                    <div class="loader">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {articals ? (
                  articals.map((ele) => (
                    <div>
                      <div className="article-card-container aricaldiv">
                        <div className="artical-card-img-div">
                          <img
                            src={ele.image}
                            alt="card image"
                            className="article-card-image"
                          />
                        </div>

                        <p className="article-card-heading">
                          {ele.title.slice(0, 30)}....
                        </p>
                        <p
                          className="small-text theme-grey-text article-card-content aricaldiv"
                          dangerouslySetInnerHTML={{
                            __html: ele?.description.slice(0, 190),
                          }}
                        ></p>
                        <div className=" ">
                          <a
                            className="cursor-pointer small-text"
                            onClick={() => handelOnclickArtical(ele)}
                            target="_blank"
                          >
                            READ MORE
                          </a>
                          <ArrowRight className="ap-read-more-icon" />
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="loader-div ">
                    <div className="loader text-center">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ArticleDetails;
