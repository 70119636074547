import React, { useState } from "react";
import { Dropdown, Form } from "react-bootstrap";

import "../../Pages/MandatedProperties/MandatedProperties.css";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';


import Button from "../../Components/Button/Button";
let Mobile_banner = "https://d2s80gmgq3vhu4.cloudfront.net/HomeSlideImages/assets/Services_mobile/find-property.jpg";

function MandatedProperties() {
  const [propertilist, SetPropertilist] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getPropertiList();
  }, []);

  useEffect(() => {
    filterListFuction();
  }, []);

  const getPropertiList = async () => {
    const data = await axios.get(
      `https://rr-api.moshimoshi.cloud/api/rr/category/properties`
    );
    SetPropertilist(data.data);
  };

  const clickProperti = (ele) => {
    navigate("/mandated-properties-internal", {
      state: {
        ele,
      },
    });
  };

  const [locationList, setLocationList] = useState([]);
  const [spaceList, setSpaceList] = useState([]);
  const [labelList, setLabelList] = useState([]);

  const locationpay = {
    status: "location",
  };
  const typeSpacepay = {
    status: "property_type",
  };
  const typeLable = {
    status: "label",
  };

  const filterListFuction = async () => {
    const data = await axios.post(
      "https://rr-api.moshimoshi.cloud/api/rr/property_filter_list",
      locationpay
    );

    const data1 = await axios.post(
      "https://rr-api.moshimoshi.cloud/api/rr/property_filter_list",
      typeSpacepay
    );

    const data3 = await axios.post(
      "https://rr-api.moshimoshi.cloud/api/rr/property_filter_list",
      typeLable
    );

    console.log(data1.data, "data1data1.data");

    const uniqueIds = [];

    const unique = data.data.filter((element) => {
      const isDuplicate = uniqueIds.includes(element.location);

      if (!isDuplicate) {
        uniqueIds.push(element.location);

        return true;
      }

      return false;
    });

    const uniqueSpace = data1.data.filter((element) => {
      const isDuplicate = uniqueIds.includes(element.property_type);

      if (!isDuplicate) {
        uniqueIds.push(element.property_type);

        return true;
      }

      return false;
    });

    const uniqueLable = data3.data.filter((element) => {
      const isDuplicate = uniqueIds.includes(element.property_type);

      if (!isDuplicate) {
        uniqueIds.push(element.property_type);

        return true;
      }

      return false;
    });

    // 👇️ [{id: 1, name: 'Tom'}, {id: 2, name: 'Nick'}]
    console.log(uniqueLable, "uniqueLable");
    setSpaceList(uniqueSpace);
    setLocationList(unique);

    setLabelList(uniqueLable);
  };

  const [selectCity, setSelectCity] = useState();
  const [selectType, setSelectType] = useState();
  const [selectLable, setSelectLable] = useState();

  const filterCityOnChage = (e) => {
    setSelectCity(e.target.value);
    console.log(e.target.value);
  };

  const filterTypeOnChage = (e) => {
    setSelectType(e.target.value);
    console.log(e.target.value);
  };

  const filterLableOnChage = (e) => {
    setSelectLable(e.target.value);
    console.log(e.target.value);
  };

  const updateFilter = async (ele) => {
    propertilist.splice(0);
    const cityapayload = {
      location: selectCity,
      property_type: selectType,
      label: selectLable,
    };
    console.log(cityapayload, "cityapayload");
    const data = await axios.post(
      "https://rr-api.moshimoshi.cloud/api/rr/property_filter",
      cityapayload
    );
    SetPropertilist(data.data);
  };
  return (
    <>
     <Helmet>
        <title>Explore the Listings Diverse Array of Properties Available


</title>
        <meta name="description" content="Browse through a wide range of real estate listings offered by Realistic Realtors, featuring diverse and appealing properties for every buyer's needs.


" />
      </Helmet>
      <h1 className="medium-heading1012 ap-head shadow-sm"> Find Properties</h1>
      <div className="maindivproperties">

        <div className="mobiledivimg">
          <div className="mandated-section-one-image">
            <div className="ssp-banner-text-container">
              <h1 className="medium-heading101 ssp-banner-heading text-md-white mandated-color">
                Browse from a list of exclusive properties
              </h1>
            </div>
          </div>
          <img
            src={Mobile_banner}
            className="mandated-image"
            alt="mandated-image"
          />
        </div>
        <div className="mandated-section-two sectiontwolistproperty">
          <h1 className="medium-heading ssp-banner-heading text-md-white  mandekstop ">
            Browse from a list of exclusive properties
          </h1>
          <div className="mandated-section-two-sub-section-one">
            <div class="row filter-mandated">
              <div class="col-sm text-start">
                <p className="dropdown-one">Select a city</p>
                <Form.Select
                  aria-label="Default select example"
                  onClick={filterCityOnChage}
                >
                  <option>Select a city </option>

                  {locationList.map((ele) => (
                    <>
                      <option> {ele.location}</option>
                    </>
                  ))}
                </Form.Select>
              </div>
              <div class="col-sm text-start">
                <p className="dropdown-one">Type of Space</p>
                <div className="">
                  <Form.Select
                    aria-label="Default select example"
                    onClick={filterTypeOnChage}
                  >
                    <option>Type of Space</option>
                    {/* <option>Warehouse Space</option> */}

                    {spaceList.map((ele) => (
                      <>
                        <option>{ele.property_type}</option>
                        {/* <option>Warehouse Spa</option> */}
                      </>
                    ))}
                  </Form.Select>
                </div>
              </div>
              <div class="col-sm text-start">
                <p className="dropdown-one">Available For</p>
                <div>
                  <Form.Select
                    aria-label="Default select example"
                    onClick={filterLableOnChage}
                  >
                    <option>Available For</option>
                    {labelList.map((ele) => (
                      <>
                        <option>Sale</option>
                        <option>{ele.label}</option>
                        {/* <option>Warehouse Spa</option> */}
                      </>
                    ))}
                  </Form.Select>
                </div>
              </div>
              <div class="col-sm text-end">
                <div className="button-div">
                  {" "}
                  <Button
                    onClick={updateFilter}
                    className={"dark-bg-white-color art-home-button"}
                    title={"Search"}
                  />
                </div>
              </div>
            </div>
          </div>
       

          <div className="row justify-content-between mandated-section-two-sub-section-two ">
            {propertilist ? (
              propertilist.map((ele) => (
                <>
                  <div className="col-md-6 my-3">
                    <div className="first-div">
                      <div className="second-div">
                        <img
                          onClick={() => clickProperti(ele)}
                          src={ele.image}
                          alt="card image"
                          className="mandated-article-image"
                          onClick={() => clickProperti(ele)}
                          alt=""
                        />
                        <div className="mandated-article-footer py-2">
                          <div style={{ display: "flex" }}>
                            <div
                              style={{ marginTop: "30px", marginLeft: "5%" }}
                            >
                              <p
                                className="mandated-article-footer-box1"
                                onClick={() => clickProperti(ele)}
                              >
                                {ele?.label}
                              </p>
                            </div>
                            <div
                              style={{
                                marginTop: "30px",
                                marginLeft: "10px",
                              }}
                            >
                              <p
                                className="mandated-article-footer-box1"
                                onClick={() => clickProperti(ele)}
                              >
                                {ele?.property_type}
                              </p>
                            </div>
                          </div>
                          <p
                            className="mandated-article-footer-title"
                            style={{ marginLeft: "5%" }}
                          >
                            {ele.location}
                          </p>
                          <p className="mandated-article-footer-title1 mandated-article-head">
                            {ele.title}
                          </p>
                          <p className="mandated-article-footer-title2 mandated-article-title2">
                            Size: {ele.size} SqFt
                          </p>
                          <div className="enquerynowproperty ">
                            <a
                              // href="/mandated-properties-internal"
                              className="mandated-article-footer-title3 text-decoration-none cursor-pointer"
                              onClick={() => clickProperti(ele)}
                            >
                              <span className="">Enquire now</span>
                              <span className="">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="35"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-arrow-right"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                  />
                                </svg>
                              </span>
                              <p className="mandated-article-line"></p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))
            ) : (
              <h1>Loading</h1>
            )}
            {/* {propertilist.length == 0 ? (
              <p className="fs-2 text-center medium-heading">
                No properties found for selected input
              </p>
            ) : (
              ""
            )} */}
          </div>

          <div className="article-home-button text-center my-5">
            <p className="h-sec-4-top-text fs-2">
              If you haven't found what you are looking for
            </p>

            <a href="/form">
              <Button
                className={"dark-bg-white-color art-home-button"}
                title={"List your requirement"}
              />
            </a>
          </div>
          {/* <div className="row justify-content-between mandated-section-two-sub-section-tree">
          <div className="col-md-6">
            <MandatedPropertiesArticle />
          </div>
          <div className="col-md-6">
            <MandatedPropertiesArticle />
          </div>
        </div> */}
        </div>
      </div>
    </>
  );
}

export default MandatedProperties;
